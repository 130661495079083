import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: "#2A3F16",
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated from palette.primary.main,
    },
    secondary: {
      // light: will be calculated from palette.primary.main,
      main: "#F3CE02",
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated from palette.primary.main,
    },
    // customize palette
    contentBackground: {
      main: "#EDEDED",
    },
  },
  typography: {
    fontFamily: "Open Sans",
    fontSize: 14,
  },
});
