import "./App.css";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./theme/main";
import Main from "./components/Main";

import BottomFooter from "./components/BottomFooter";
import Navbar from "./components/NavBar";
import { useEffect } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import SmartVisit from "./components/ProductsSub/SmartVisit";
//import { AppConfig } from "./config";

function App() {
  useEffect(() => {
    // TODO: setup wechat sign backend server
    //window.wx.config({
    //  debug: false,
    //  appId: AppConfig.appId,
    //  timestamp: Math.floor(Date.now() / 1000),
    //  nonceStr: "hanpingtonghe",
    //  signature: "sign_hanpingtonghe",
    //  jsApiList: ["updateAppMessageShareData"],
    //});
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <div className="page-container">
        <div className="content-wrap">
          <Navbar></Navbar>
          <Routes>
            <Route path="/" element={<Main></Main>}></Route>
            <Route
              path="/product/smart-visit"
              element={<SmartVisit></SmartVisit>}
            ></Route>
            <Route
              path="*"
              element={<Navigate to="/" replace></Navigate>}
            ></Route>
          </Routes>
        </div>
        <footer className="footer">
          <BottomFooter></BottomFooter>
        </footer>
      </div>
    </ThemeProvider>
  );
}

export default App;
