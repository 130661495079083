import { Grid, Container } from "@mui/material";
import React from "react";
import { makeStyles } from "@mui/styles";

const useStyle = makeStyles((theme) => ({
  gridContainer: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  title: {
    paddingTop: theme.spacing(20),
    paddingBottom: theme.spacing(5),
  },
  content: {
    fontSize: 12,
    textAlign: "center",
    color: theme.palette.primary.contrastText,
    fontFamily: theme.typography.fontFamily,
  },
  url: {
    textDecoration: "underline",
    color: theme.palette.primary.contrastText,
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      color: theme.palette.secondary.main,
    },
  },
}));

function BottomFooter() {
  const classes = useStyle();
  return (
    <div className={classes.gridContainer}>
      <Container maxWidth="xl">
        <Grid
          container
          justifyContent="space-between"
          alignContent="center"
          alignItems="center"
        >
          <Grid item xs={12} md={2}>
            <div className={classes.content}>
              &copy; 2021 - {new Date().getFullYear()} 北京汉平通和科技有限公司
            </div>
          </Grid>

          <Grid item xs={12} md={2}>
            <div className={classes.content}>
              <a
                href="https://beian.miit.gov.cn"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.url}
              >
                京ICP备2021040556号-1
              </a>
            </div>
          </Grid>

          <Grid item xs={12} md={2}>
            <div className={classes.content}>
              <a
                href="mailto: info@hpth.cn"
                rel="noopener noreferrer"
                className={classes.url}
              >
                info@hpth.cn
              </a>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default BottomFooter;
