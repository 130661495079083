import { Container, Grid, Paper, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";

const useStyle = makeStyles((theme) => ({
  gridContainer: {
    backgroundImage: "url(/imgs/tech-bg2.jpg)",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundColor: theme.palette.contentBackground.main,
  },
  container: {
    paddingBottom: theme.spacing(25),
    fontWeight: "bold",
    textAlign: "center",
    borderColor: theme.palette.primary.main,
  },
  paper: {
    padding: theme.spacing(5),
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(3),
      padding: theme.spacing(3),
    },
  },
  title: {
    paddingTop: theme.spacing(15),
    paddingBottom: theme.spacing(15),
    fontWeight: "bold",
    color: theme.palette.primary.main,
  },
}));

function Tech() {
  const classes = useStyle();
  const width = 6;
  return (
    <Grid container className={classes.gridContainer}>
      <Container maxWidth="xl" className={classes.container}>
        <Typography variant="h3" className={classes.title} id="tech">
          研发能力
        </Typography>
        <Grid container justifyContent="space-between">
          <Grid item xs={width} sm={2}>
            <Paper elevation={16} square={true} className={classes.paper}>
              精准分析
            </Paper>
          </Grid>
          <Grid item xs={width} sm={2}>
            <Paper elevation={16} square={true} className={classes.paper}>
              需求调研
            </Paper>
          </Grid>
          <Grid item xs={width} sm={2}>
            <Paper elevation={16} square={true} className={classes.paper}>
              定制化设计
            </Paper>
          </Grid>
          <Grid item xs={width} sm={2}>
            <Paper elevation={16} square={true} className={classes.paper}>
              高标准实施
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
}

export default Tech;
