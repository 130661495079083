import { Grid, Typography } from "@mui/material";
import React from "react";
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  title: {
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },

  subTitle: {
    paddingTop: theme.spacing(1),
    color: theme.palette.primary.light,
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  descriptionFirst: {
    paddingTop: theme.spacing(5),
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      paddingTop: theme.spacing(5),
      padding: theme.spacing(2),
      fontSize: 16,
    },
  },
  description: {
    paddingTop: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      paddingTop: theme.spacing(1),
      padding: theme.spacing(2),
      fontSize: 16,
    },
  },
  intro: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(12),
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      marginBottom: theme.spacing(5),
    },
  },
}));

function ProductPreview({ title, url, route, subTitle, descriptionList }) {
  const classes = useStyles();
  return (
    <>
      <Typography variant="h5" className={classes.title}>
        {title}
      </Typography>
      <Typography className={classes.subTitle}>{subTitle}</Typography>
      {descriptionList.map((item, index) => (
        <div key={index}>
          {index === 0 ? (
            <Typography className={classes.descriptionFirst}>{item}</Typography>
          ) : (
            <Typography className={classes.description}>{item}</Typography>
          )}
        </div>
      ))}

      <div className={classes.intro}>
        {route === "" || route === undefined ? (
          <a
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "underline" }}
          >
            产品介绍
          </a>
        ) : (
          <Link to={route}>产品细节</Link>
        )}
      </div>
    </>
  );
}

export default ProductPreview;
