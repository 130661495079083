import { Container, Typography, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";

const useStyle = makeStyles((theme) => ({
  gridContainer: {
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundColor: theme.palette.contentBackground.main,
  },
  container: {
    paddingBottom: theme.spacing(10),
    textAlign: "center",
  },
  title: {
    paddingTop: theme.spacing(15),
    textAlign: "center",
    fontWeight: "bold",
    color: theme.palette.primary.main,
  },
  gridLeft: {
    paddingLeft: theme.spacing(20),
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(10),
      paddingLeft: theme.spacing(0),
      textAlign: "center",
    },
  },
  qrcode: {
    marginTop: theme.spacing(5),
    height: 120,
    width: 120,
    backgroundImage: "url(/imgs/hpth_wechat_qrcode.jpg)",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
}));

function Contact() {
  const classes = useStyle();
  return (
    <div className={classes.gridContainer}>
      <Container maxWidth="xl" className={classes.container}>
        <Typography variant="h3" className={classes.title} id="contact">
          联系我们
        </Typography>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs={12} sm={6} className={classes.gridLeft}>
            <Typography variant="body2">北京汉平通和科技有限公司</Typography>
            <Typography variant="body2">
              北京市海淀区西北旺中关村壹号C1座9层902室
            </Typography>
            <Typography variant="body2">info@hpth.cn</Typography>
            <Typography variant="body2">
              010-53381257（9:00 - 18:00）
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container alignItems="center" justifyContent="center">
              <Grid item>
                <div className={classes.qrcode}></div>
              </Grid>
            </Grid>

            <Typography variant="caption" align="center">
              汉平通和微信服务号
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default Contact;
