import { Grid, Container, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import Products from "./Products";
import Tech from "./Tech";
import News from "./News";
import Contact from "./Contact";
import Customers from "./Customers";

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    paddingTop: theme.spacing(15),
    paddingBottom: theme.spacing(40),
    backgroundImage: "url(/imgs/main-bg.jpg)",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundColor: theme.palette.contentBackground.main,
  },
  title: {
    paddingTop: theme.spacing(30),
    fontSize: 56,
    fontWeight: "bold",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(20),
      fontSize: 28,
    },
  },
  content: {
    textAlign: "center",
    paddingTop: theme.spacing(10),
    color: theme.palette.primary.dark,
    fontSize: 18,
    [theme.breakpoints.down("sm")]: {
      color: theme.palette.primary.light,
      fontSize: 14,
    },
  },
  contentSub: {
    textAlign: "center",
    paddingTop: theme.spacing(1),
    fontSize: 18,
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
}));

function Main() {
  const classes = useStyles();
  return (
    <>
      <Grid container className={classes.gridContainer} id="main">
        <Container maxWidth="xl">
          <div className={classes.title}>政企信息化解决方案服务商</div>
          <Typography className={classes.content}>
            专长于信息系统定制开发，计算机视觉技术应用，支持本地化、私有云、公有云等多种场景
          </Typography>
          <Typography className={classes.contentSub}>
            技术过硬，稳步前进
          </Typography>
        </Container>
      </Grid>
      <Tech></Tech>
      <Products></Products>
      <Customers></Customers>
      <News></News>
      <Contact></Contact>
    </>
  );
}

export default Main;
