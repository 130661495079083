import { Container, Typography, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import NewsItem from "./NewsSub/NewsItem";

const useStyle = makeStyles((theme) => ({
  gridContainer: {
    backgroundImage: "url(/imgs/news-bg.jpg)",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundColor: theme.palette.contentBackground.main,
    [theme.breakpoints.down("sm")]: {
      backgroundImage: "url(/imgs/news-bg-mobile.jpg)",
    },
  },
  container: {
    paddingBottom: theme.spacing(25),
    textAlign: "center",
  },
  title: {
    paddingTop: theme.spacing(15),
    textAlign: "center",
    fontWeight: "bold",
    color: theme.palette.primary.main,
  },
}));

function News() {
  const classes = useStyle();

  return (
    <div className={classes.gridContainer}>
      <Container maxWidth="xl" className={classes.container}>
        <Typography variant="h3" className={classes.title} id="news">
          新闻
        </Typography>
        <Grid container justifyContent="flex-end">
          <Grid item xs={12} md={6}>
            <NewsItem
              title="汉平通和入驻高新技术产业区园区 - 中关村壹号"
              content="中关村壹号作为北京北部研发服务和高新技术产业聚集区的标志性建筑群，汉平通和有幸于2021年末入驻。对于产业和行业的快速发展有了新的认知之余，也同时深知大科技、大信息背景下对新时代科技公司提出了精于技术、长于服务的新要求。我公司秉承“技术过硬，稳步前进”之信念，定当坚定不移，乘风破浪。"
            ></NewsItem>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default News;
