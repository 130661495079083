import { Container, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { AppConfig } from "../config";
import ProductPreview from "./ProductsSub/ProductPreview";

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    backgroundImage: "url(/imgs/prod-bg.jpg)",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundColor: theme.palette.contentBackground.main,
    [theme.breakpoints.down("sm")]: {
      backgroundImage: "url(/imgs/prod-bg-mobile.jpg)",
    },
  },
  title: {
    paddingTop: theme.spacing(15),
    paddingBottom: theme.spacing(15),
    textAlign: "center",
    fontWeight: "bold",
    color: theme.palette.primary.main,
  },
  container: {
    paddingBottom: theme.spacing(25),
  },
  grid: {
    padding: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      paddingBottom: theme.spacing(5),
    },
  },
}));

function Products() {
  const classes = useStyles();
  return (
    <div className={classes.gridContainer}>
      <Container maxWidth="xl" className={classes.container}>
        <Typography variant="h3" className={classes.title} id="prod">
          产品介绍
        </Typography>
        <Grid container justifyContent="space-around">
          <Grid item xs={12} md={7} className={classes.grid}>
            <ProductPreview
              title="速翼通停车场信息管理系统"
              url="https://mp.weixin.qq.com/s/m9EpgPWvuMIgOD5r8QyTGg"
              subTitle="物联网支持、无人值守、自动分账"
              descriptionList={[
                "系统代码完全自主研发，融合互联网+、物联网、大数据、云计算、人工智能识别、无感支付等多项技术，打造行业领先的多场景智慧停车系统。",
              ]}
            ></ProductPreview>
          </Grid>
          <Grid item xs={12} md={7} className={classes.grid}>
            <ProductPreview
              title="无纸化调研问卷信息管理系统"
              url="https://mp.weixin.qq.com/s/-HZTS8ENU4PPINooSVNiNg"
              subTitle="内网部署、数据采集，实时统计"
              descriptionList={[
                "汉平通和无纸化调研问卷信息管理系统可依据用户需求生成符合要求的问卷进行调研、测评、投票。提供数据采集、自定义报表、调查结果分析等系列服务。",
                "可定制化内网部署，提升企业信息反馈效率，精细化业务管理流程。",
              ]}
            ></ProductPreview>
          </Grid>
          <Grid item xs={12} md={7} className={classes.grid}>
            <ProductPreview
              title="智慧访客证信息管理系统"
              url={AppConfig.smartVisitUrl}
              route="product/smart-visit"
              subTitle="多园区支持、访客证管理、角色管理、数据统计、使用便捷"
              descriptionList={[
                "汉平通和智慧访客证信息管理系统是一个致力于解决中大型园区、单位、院校和企业等内部场所纸质通行证分发管理不便所研发的信息化软件平台。",
                "可实现管理员分发访客证，访客扫码展示访客证，统计访客到访信息，从而提升园区、单位、院校和企业现有资源利用率，降低人力成本，节约纸质消耗。便捷管理，降本增效。",
              ]}
            ></ProductPreview>
          </Grid>
          {/**
           * <Grid item xs={12} md={4} className={classes.grid}>
            <Product
              title="汉平通和“春晓”，设备状态监控预警平台"
              subTitle="设备状态监控，场景内容识别，仪表数据读取"
              descriptionList={[
                "通过摄像头采集设备、仪表、场景的图像数据，传输到汉平通和“春晓”平台处理图像数据并提取指定信息。完成图像到设备运行状态数据的高精度转化。",
                "汉平通和“春晓”支持个人和企业用户，带有完整的权限管理功能。通过不间断的分析传入的设备图像数据，实现7 x 24小时设备运行状态监控，设备异常通知，免去人工监控的疏忽和遗漏。有效提升监测密度、降低监控误报率。通过“春晓”的可视化模块，可轻松实现历史数据回测，当前设备运行数据实时展示，多人、异地快速查看设备状态等功能。",
              ]}
            ></Product>
          </Grid>
           * 
           */}
        </Grid>
      </Container>
    </div>
  );
}

export default Products;
