import {
  Container,
  AppBar,
  Toolbar,
  SwipeableDrawer,
  Box,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { isMobile } from "react-device-detect";

const useStyle = makeStyles((theme) => ({
  logo: {
    height: 40,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(2),
  },
  navTitle: {
    fontSize: 16,
    fontWeight: "bold",
  },
  navItem: {
    fontSize: 16,
    paddingRight: theme.spacing(3),
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      cursor: "pointer",
      color: theme.palette.secondary.light,
    },
  },
  lstItem: {
    fontWeight: "bold",
    fontSize: 18,
    color: theme.palette.primary.main,
    padding: theme.spacing(1),
  },
  lstItemClose: {
    fontWeight: "bold",
    fontSize: 18,
    color: "#B30000",
    padding: theme.spacing(1),
  },
  divider: {
    color: theme.palette.primary.main,
  },
}));

function Navbar() {
  const classes = useStyle();
  const [menuOpen, setMenuOpen] = useState(false);

  const navList = [
    { name: "研发能力", id: "tech" },
    { name: "产品介绍", id: "prod" },
    { name: "服务客户", id: "customer" },
    { name: "新闻", id: "news" },
    { name: "联系我们", id: "contact" },
  ];

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setMenuOpen(open);
  };

  function scrollToComponent(id) {
    var element = document.getElementById(id);
    element.scrollIntoView({
      behavior: "smooth",
      inline: "nearest",
    });
  }

  return (
    <AppBar position="fixed">
      <Container maxWidth="xl">
        <Toolbar>
          <img
            src="/website-logo.png"
            className={classes.logo}
            alt="logo"
          ></img>
          <div
            className={classes.navTitle}
            onClick={() => scrollToComponent("main")}
          >
            北京汉平通和科技
          </div>
          <div style={{ flexGrow: 1 }}></div>
          {isMobile ? (
            <>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={toggleDrawer(true)}
              >
                <MenuIcon />
              </IconButton>
              <SwipeableDrawer
                anchor="right"
                open={menuOpen}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
              >
                <Box sx={{ width: 180 }} role="presentation">
                  <List>
                    <ListItem
                      button
                      divider
                      key="首页"
                      onClick={() => scrollToComponent("main")}
                    >
                      <ListItemText
                        className={classes.lstItem}
                        primary="首页"
                      />
                    </ListItem>

                    {navList.map((item) => (
                      <ListItem
                        button
                        divider
                        key={item.name}
                        onClick={() => scrollToComponent(item.id)}
                      >
                        <ListItemText
                          className={classes.lstItem}
                          primary={item.name}
                        />
                      </ListItem>
                    ))}

                    <ListItem
                      button
                      divider
                      key="exit"
                      onClick={() => setMenuOpen(false)}
                    >
                      <ListItemText
                        className={classes.lstItemClose}
                        primary="关闭边栏"
                      />
                    </ListItem>
                  </List>
                </Box>
              </SwipeableDrawer>
            </>
          ) : (
            <>
              {navList.map((item) => (
                <div
                  key={item.name}
                  className={classes.navItem}
                  onClick={() => scrollToComponent(item.id)}
                >
                  {item.name}
                </div>
              ))}
            </>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default Navbar;
