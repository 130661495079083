import { Container, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";

const useStyle = makeStyles((theme) => ({
  gridContainer: {
    backgroundImage: "url(/imgs/customer-bg.png)",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundColor: theme.palette.contentBackground.main,
  },
  container: {
    paddingBottom: theme.spacing(25),
    fontWeight: "bold",
    textAlign: "center",
    borderColor: theme.palette.primary.main,
  },
  paper: {
    padding: theme.spacing(5),
    borderRadius: 50,
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(3),
    },
  },
  title: {
    paddingTop: theme.spacing(15),
    paddingBottom: theme.spacing(15),
    fontWeight: "bold",
    color: theme.palette.primary.main,
  },
  customerLogo: {
    width: 200,
    [theme.breakpoints.down("sm")]: {
      width: 150,
    },
  },
  customerName: {
    fontSize: 18,
    fontWeight: "bold",
    padding: theme.spacing(0.5),
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
  grid: {
    padding: theme.spacing(0.5),
    [theme.breakpoints.down("sm")]: {
      paddingBottom: theme.spacing(3),
    },
  },
}));

function Customers() {
  const classes = useStyle();
  const customerLst = [
    { name: "某驻京部队", logoPath: "mouzhujingbudui.png" },
    { name: "山西省人民政府", logoPath: "shanxishengrenminzhengfu.png" },
    { name: "中国建筑一局", logoPath: "zhongjianyiju.png" },
    { name: "中关村壹号科技园", logoPath: "zhongguancunyihao.png" },
    { name: "北京城建集团", logoPath: "bucg.png" },
    { name: "山西焦煤集团", logoPath: "jiaomeijituan.png" },
  ];
  return (
    <Grid container className={classes.gridContainer}>
      <Container maxWidth="xl" className={classes.container}>
        <Typography variant="h3" className={classes.title} id="customer">
          服务客户
        </Typography>
        <Grid container justifyContent="center">
          {customerLst.map((item, index) => (
            <Grid item xs={6} sm={2} key={index} className={classes.grid}>
              <img
                alt={item.name}
                src={"/imgs/customers/" + item.logoPath}
                className={classes.customerLogo}
              ></img>
              <Typography className={classes.customerName}>
                {item.name}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Grid>
  );
}

export default Customers;
