import React from "react";
import { makeStyles } from "@mui/styles";
import { Grid, Container, Typography, Divider, Button } from "@mui/material";
import { AppConfig } from "../../config";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(15),
    marginBottom: theme.spacing(10),
  },
  title: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    textAlign: "center",
    fontWeight: "bold",
    color: theme.palette.primary.main,
  },
  subTitle: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    fontSize: [30, "!important"],
  },
  content: {
    paddingBottom: theme.spacing(2),
  },
  imgFeature: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    alignItems: "center",
    width: "100%",
  },
  qrcode: {
    width: "100%",
    marginTop: theme.spacing(7),
  },
  url: {
    marginTop: theme.spacing(3),
  },
  btnBack: {
    paddingTop: theme.spacing(2),
  },
}));

function SmartVisit({ url }) {
  const classes = useStyles();
  return (
    <Container maxWidth="xl">
      <Grid container className={classes.container}>
        <Grid item xs={12}>
          <Typography variant="h3" className={classes.title} id="prod">
            智慧访客证
          </Typography>

          <Divider variant="fullWidth"></Divider>
        </Grid>
        <Grid item xs={12} sm={9}>
          <Typography className={classes.subTitle}>系统概述</Typography>
          <Typography variant="body" className={classes.content}>
            汉平通和智慧访客证信息管理系统是一个致力于解决中大型园区、单位、院校和企业等内部场所纸质通行证分发管理不便所研发的信息化软件平台。可实现管理员分发访客证，访客扫码展示访客证，统计访客到访信息，从而提升园区、单位、院校和企业现有资源利用率，降低人力成本，节约纸质消耗。便捷管理，降本增效。
          </Typography>

          <Typography className={classes.subTitle}>系统特性</Typography>
          <Typography variant="body" className={classes.content}>
            系统代码完全自主研发，具有多园区支持、权限管理、访客证有效期管理、访客数据统计、使用便捷等特点。通过拆分数据逻辑，抽象微服务设计，使系统具备高可用，高稳定，高扩展等特性。
          </Typography>

          <Grid container justifyContent="center">
            <Grid item>
              <img
                alt="features"
                src="/products/smart-visit-features.PNG"
                className={classes.imgFeature}
              ></img>
            </Grid>
          </Grid>

          <Typography className={classes.subTitle}>
            多园区支持（特性一）
          </Typography>
          <Typography variant="body" className={classes.content}>
            系统支持多园区配置，每个园区可配置多家单位、企业。在满足园区管理者对访客整体管理的同时，也提升了单位和企业邀请访客的便利性。
          </Typography>

          <Grid container justifyContent="center">
            <Grid item>
              <img
                alt="features"
                src="/products/smart-visit-multi-park.PNG"
                className={classes.imgFeature}
              ></img>
            </Grid>
          </Grid>

          <Typography className={classes.subTitle}>
            角色管理（特性二）
          </Typography>
          <Typography variant="body" className={classes.content}>
            园区管理员具有园区内单位管理、企业管理和园区访客证管理多重权限。管理员可配置园区访客总数；可创建、修改、管理园区单位信息、企业信息和访客证配额；可为园区单位、企业设置独立管理员，实现单位、企业内部精细管理、灵活调度。系统使用手机号作为唯一标识，确保安全性、实名性的同时有效降低了使用门槛。
          </Typography>

          <Grid container justifyContent="center">
            <Grid item>
              <img
                alt="features"
                src="/products/smart-visit-multi-role.PNG"
                className={classes.imgFeature}
              ></img>
            </Grid>
          </Grid>

          <Typography variant="body" className={classes.content}>
            单位管理员可管理所属单位、企业信息和访客证；可定制化配置带有单位、企业风格的访客证，包括但不限于名称、地址、logo，访客证背景图等；可便捷的通过手机号创建访客证，保证点对点邀请，确保责任到人。提升单位、企业访客邀请效率，简化访客到访流程。
          </Typography>

          <Grid container justifyContent="center">
            <Grid item>
              <img
                alt="features"
                src="/products/smart-visit-party-admin.PNG"
                className={classes.imgFeature}
              ></img>
            </Grid>
          </Grid>

          <Typography className={classes.subTitle}>
            访客证管理（特性三）
          </Typography>
          <Typography variant="body" className={classes.content}>
            智慧访客证管理包含：分发访客证、更新访客证有效期、回收访客证等。保证访客通行的同时，解决传统纸质通行证分发流程长、管理难度高、回收周期长等痛点。在此基础上还支持有效期内多次通行、失效禁止通行等特性。
          </Typography>

          <Grid container justifyContent="center">
            <Grid item>
              <img
                alt="features"
                src="/products/smart-visit-justi-manage.PNG"
                className={classes.imgFeature}
              ></img>
            </Grid>
          </Grid>

          <Typography className={classes.subTitle}>
            使用便捷（特性四）
          </Typography>
          <Typography variant="body" className={classes.content}>
            访客用户只需在到访处通过智慧访客证小程序端扫描园区专属二维码，即可展示有效访客证，确保快速通行。一键完成访客登记，访客身份确认，到访处查验等流程。
          </Typography>

          <Grid container justifyContent="center">
            <Grid item>
              <img
                alt="features"
                src="/products/smart-visit-convient.PNG"
                className={classes.imgFeature}
              ></img>
            </Grid>
          </Grid>

          <Typography className={classes.subTitle}>
            数据统计（特性五）
          </Typography>
          <Typography variant="body" className={classes.content}>
            访客到访扫码后，到访信息随即存入智慧访客证信息管理系统数据库，方便园区管理员和单位管理员快速检索。系统具备可视化统计功能，园区管理员和单位管理员可多维度快速获取访客到访时间统计，访客到访频次统计。可追溯历史到访信息，导出访客登记表，生成到访数据看板和访客数据大屏。统计信息直观明了，数据同步快速便捷，加速数据流转，为管理者决策提供高质量数据支撑。
          </Typography>

          <Grid container justifyContent="center">
            <Grid item>
              <img
                alt="features"
                src="/products/smart-visit-data-analysis.PNG"
                className={classes.imgFeature}
              ></img>
            </Grid>
          </Grid>

          <Typography className={classes.subTitle}>
            信息安全（特性六）
          </Typography>
          <Typography variant="body" className={classes.content}>
            系统基于微服务开发，满足Https安全传输标准。所有数据存放于云端，通过严格的权限管理确保数据的可访问范围。保证数据安全、存储安全的同时提升数据访问的便利性。
          </Typography>

          <Typography className={classes.subTitle}>合作模式</Typography>
          <Typography variant="body" className={classes.content}>
            智慧访客证信息管理系统分为旗舰版和标准版，基础功能为两者共有。
          </Typography>
          <Grid container justifyContent="center">
            <Grid item>
              <img
                alt="features"
                src="/products/smart-visit-coprate.PNG"
                className={classes.imgFeature}
              ></img>
            </Grid>
          </Grid>

          <Typography className={classes.subTitle}>旗舰版</Typography>
          <Typography variant="body" className={classes.content}>
            旗舰版包含智慧访客证信息管理系统所有基础功能，提供专人技术支持，售后服务，定制化升级等服务。
          </Typography>
          <Grid container justifyContent="center">
            <Grid item>
              <img
                alt="features"
                src="/products/smart-visit-primere.PNG"
                className={classes.imgFeature}
              ></img>
            </Grid>
          </Grid>

          <Typography className={classes.subTitle}>标准版</Typography>
          <Typography variant="body" className={classes.content}>
            标准版包含智慧访客证信息管理系统所有基础功能，支持快速部署，可升级旗舰版。
          </Typography>
          <Grid container justifyContent="center">
            <Grid item>
              <img
                alt="features"
                src="/products/smart-visit-standard.PNG"
                className={classes.imgFeature}
              ></img>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Grid container justifyContent="center">
            <Grid item xs={7}>
              <img
                alt="weapp-qrcode"
                src="/products/weapp-qrcode.jpg"
                className={classes.qrcode}
              ></img>
            </Grid>
            <Grid container justifyContent="center">
              <Grid item>
                <Typography variant="caption" align="center">
                  微信扫码体验
                </Typography>
              </Grid>
            </Grid>
            <div className={classes.url}>
              <a
                href={AppConfig.smartVisitUrl}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "underline" }}
              >
                查看产品介绍
              </a>
            </div>
          </Grid>
        </Grid>

        <Grid container justifyContent="center">
          <Grid item className={classes.btnBack}>
            <Button
              component={Link}
              to="/"
              variant="contained"
              sx={{ borderRadius: 0 }}
            >
              返回首页
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

export default SmartVisit;
